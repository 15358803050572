import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

import { SmoothScrollToService } from '../../../shared/services/smooth-scroll-to.service';

@Component({
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements AfterViewInit {

  @ViewChild('home', {static: true}) home: ElementRef;
  @ViewChild('whatWeDo', {static: true}) whatWeDo: ElementRef;
  @ViewChild('workingPlace', {static: true}) workingPlace: ElementRef;
  @ViewChild('contactUs', {static: true}) contactUs: ElementRef;

  constructor(private readonly smoothScrollService: SmoothScrollToService) {}

  ngAfterViewInit() {
    this.smoothScrollService.register('home', this.home);
    this.smoothScrollService.register('whatWeDo', this.whatWeDo);
    this.smoothScrollService.register('workingPlace', this.workingPlace);
    this.smoothScrollService.register('contactUs', this.contactUs);
  }

}
