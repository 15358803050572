import { Directive, HostListener } from '@angular/core';
import { WindowInfoService } from '../services/window-info.service';

@Directive({
  selector: '[scrollTracker]'
})
export class ScrollTrackerDirective {

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    this.scrollTrackerService.setOffSet(window.pageYOffset);
  }

  constructor(private scrollTrackerService: WindowInfoService) { }

}
