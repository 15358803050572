import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { HomePageComponent } from './home/pages/home-page/home-page.component';
import { LoginPageComponent } from './home/pages/login-page/login-page.component';

const routes: Routes = [
  { path: '', component: HomePageComponent, },
  { path: 'login', component: LoginPageComponent, },
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
