import { Component, OnInit } from '@angular/core';
import { SmoothScrollToService } from 'src/app/shared/services/smooth-scroll-to.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isCollapsed = true;

  constructor(private smoothScrollService: SmoothScrollToService) { }

  ngOnInit() {
  }

  scrollToElement(element: string) {
    this.isCollapsed = true;
    this.smoothScrollService.scrollTo(element);
  }

}
