import { Injectable, ElementRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SmoothScrollToService {

  scrollElement: any = {};

  constructor() { }

  register(name: string, element: ElementRef) {
    this.scrollElement[name] = element;
  }

  scrollTo(element: string) {
    if (this.scrollElement[element]) {
      this.scrollElement[element].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }
}
