import { animate, AnimationBuilder, AnimationMetadata, AnimationPlayer, style } from '@angular/animations';
import { Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';

import { WindowInfoService } from '../services/window-info.service';

@Directive({
  selector: '[animateOnScroll]'
})
export class AnimateOnScrollDirective implements OnDestroy {

  private player: AnimationPlayer;
  private elOffset: number;
  private windowOffset: number;
  private windowHeight: number;
  private _animation = 'fadeIn';
  private _delay = 200;

  private offsetSubs$: Subscription;
  private heightSubs$: Subscription;

  @Input()
  set animateOnScroll(animation: string) {
    const arr = animation.split(',');
    if (arr[0].length > 0) {
      this._animation = arr[0];
    }
    if (arr[1] && !isNaN(+arr[1])) {
      this._delay = +arr[1];
    }
  }

  constructor(
    private builder: AnimationBuilder,
    private el: ElementRef,
    private windowInfoService: WindowInfoService,
    private renderer: Renderer2,
  ) {
    this.elOffset = this.getTopOffset();
    this.renderer.setStyle(this.el.nativeElement, 'opacity', 0);
    this.offsetSubs$ = this.windowInfoService.getOffset().subscribe((offset) => {
      this.windowOffset = offset;
      this.trigger();
    });
    this.heightSubs$ = this.windowInfoService.getHeight().subscribe((height) => {
      this.windowHeight = height;
      this.trigger();
    });
  }

  private trigger() {
    if (this.windowHeight === undefined || this.windowOffset === undefined) {
      return;
    }
    if (this.elOffset < this.windowOffset || this.elOffset > this.windowOffset + this.windowHeight) {
      return;
    }
    this.show();
    this.ngOnDestroy();
  }

  private show() {
    if (this.player) {
      this.player.destroy();
    }
    let factory;
    switch (this._animation) {
      case 'fadeInTop':
        factory = this.builder.build(this.fadeInDirection('Y', -100));
        break;
      case 'fadeInBottom':
        factory = this.builder.build(this.fadeInDirection('Y', 100));
        break;
      case 'fadeInRight':
        factory = this.builder.build(this.fadeInDirection('X', 100));
        break;
      case 'fadeInLeft':
        factory = this.builder.build(this.fadeInDirection('X', -100));
        break;
      default:
        factory = this.builder.build(this.fadeIn());
    }
    const player = factory.create(this.el.nativeElement);
    setTimeout(() => player.play(), this._delay);
  }

  private fadeIn(): AnimationMetadata[] {
    return [
      style({ opacity: 0 }),
      animate('500ms ease-out', style({ opacity: 1 })),
    ];
  }

  private fadeInDirection(axe: 'X' | 'Y', pixels: number): AnimationMetadata[] {
    return [
      style({ opacity: 0, transform: 'translate' + axe + '(' + pixels + 'px)' }),
      animate('500ms ease-out', style({ opacity: 1, transform: 'translateX(0)' })),
    ];
  }

  private getTopOffset() {
    const rect = this.el.nativeElement.getBoundingClientRect();
    const doc = this.el.nativeElement.ownerDocument.documentElement;
    return rect.top + window.pageYOffset - doc.clientTop;
  }

  ngOnDestroy(): void {
    this.offsetSubs$.unsubscribe();
    this.heightSubs$.unsubscribe();
  }

}
