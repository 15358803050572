import { NgModule } from '@angular/core';

import { AgmCoreModule } from '@agm/core';
import { MapComponent } from './map.component';

@NgModule({
    declarations: [MapComponent],
    imports: [
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCdkyd9cvWti74eQiy81h73yqbctWK_NDc'
        })
    ],
    exports: [MapComponent]
})
export class MapModule { }