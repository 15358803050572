import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  lat = 50.848734;
  lng = 4.351593;

  constructor() { }

  ngOnInit() {
  }

}
