import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WindowInfoService {

  private offset$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private width$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private height$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private offset = 0;
  private width = 0;
  private height = 0;

  constructor() {
    setInterval(() => {
      this.offset$.next(this.offset);
      this.width$.next(this.width);
      this.height$.next(this.height);
      console.log(this.offset, ' [', this.width, ', ', this.height, ']');
    }, 500);
  }

  setOffSet(offset: number): void {
    this.offset = offset;
  }

  setSize(width: number, height: number): void {
    this.width = width;
    this.height = height;
  }

  getOffset(): Observable<number> {
    return this.offset$.pipe(distinctUntilChanged()) as Observable<number>;
  }

  getWidth(): Observable<number> {
    return this.width$.pipe(distinctUntilChanged()) as Observable<number>;
  }

  getHeight(): Observable<number> {
    return this.height$.pipe(distinctUntilChanged()) as Observable<number>;
  }
}
