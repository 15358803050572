import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './home/pages/home-page/home-page.component';
import { LoginPageComponent } from './home/pages/login-page/login-page.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { MapModule } from './shared/components/map/map.module';
import { ScrollToTopComponent } from './shared/components/scroll-to-top/scroll-to-top.component';
import { VideoComponent } from './shared/components/video/video.component';
import { AnimateOnScrollDirective } from './shared/directives/animate-on-scroll.directive';
import { ResizeTrackerDirective } from './shared/directives/resize-tracker.directive';
import { ScrollTrackerDirective } from './shared/directives/scroll-tracker.directive';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    VideoComponent,
    FooterComponent,
    ScrollToTopComponent,
    AnimateOnScrollDirective,
    ScrollTrackerDirective,
    ResizeTrackerDirective,
    HomePageComponent,
    LoginPageComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    MapModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
