import { Directive, HostListener } from '@angular/core';
import { WindowInfoService } from '../services/window-info.service';

@Directive({
  selector: '[resizeTracker]'
})
export class ResizeTrackerDirective {

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowInfoService.setSize(window.innerWidth, window.innerHeight);
  }

  constructor(private windowInfoService: WindowInfoService) {
    this.windowInfoService.setSize(window.innerWidth, window.innerHeight);
  }

}
